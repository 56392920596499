import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import landmarks from "../../../../assets/images/blogs/pp12/landmarks.png";
import colorpicker1 from "../../../../assets/images/blogs/pp12/colorpicker1.png";
import security from "../../../../assets/images/blogs/pp12/security.png";
import pagelang from "../../../../assets/images/blogs/pp12/pagelang.png";
import clickoutside from "../../../../assets/images/blogs/pp12/click-outside.mp4";
import confetti from "../../../../assets/images/blogs/pp12/confetti.mp4";
import referenceimage from "../../../../assets/images/blogs/pp12/referenceimage.mp4";
import delay from "../../../../assets/images/blogs/pp12/delay.mp4";
import crop from "../../../../assets/images/blogs/pp12/crop.mp4";
import * as React from 'react';
export default {
  ShortCutDisplay,
  landmarks,
  colorpicker1,
  security,
  pagelang,
  clickoutside,
  confetti,
  referenceimage,
  delay,
  crop,
  React
};