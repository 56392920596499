import ShortCutDisplay from "../../../../src/components/ShortcutDisplay.jsx";
import scope from "../../../../assets/images/blogs/pp16/scope.png";
import xstyle from "../../../../assets/images/blogs/pp16/xstyle.png";
import overwriteDomain from "../../../../assets/images/blogs/pp16/overwrite-domain.png";
import mastodonstyle from "../../../../assets/images/blogs/pp16/mastodon.png";
import ariahidden from "../../../../assets/images/blogs/pp16/ariahidden.png";
import cookie3 from "../../../../assets/images/docs/cookie3.png";
import pagezoom from "../../../../assets/images/blogs/pp16/pagezoom.png";
import reducedtransparency from "../../../../assets/images/blogs/pp16/reducedtransparency.png";
import measuresentences from "../../../../assets/images/blogs/pp16/measuresentences.png";
import revealpassword from "../../../../assets/images/blogs/pp16/revealpassword.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  scope,
  xstyle,
  overwriteDomain,
  mastodonstyle,
  ariahidden,
  cookie3,
  pagezoom,
  reducedtransparency,
  measuresentences,
  revealpassword,
  React
};