require('./src/styles/fonts/inter.css');
require('./src/styles/global.css');
require('./src/styles/page.css');
require('./src/styles/prism.css');


exports.onInitialClientRender = () => {
  window.addEventListener('scroll', loadDriftWidgetOnActivity);
}

exports.onRouteUpdate = ({ prevLocation }) => {
  if (prevLocation && loadDriftWidgetOnActivity) {
    loadDriftWidgetOnActivity()
  }
}
