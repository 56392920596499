import layertree from "../../../../assets/images/docs/layertree.png";
import resolvedselector from "../../../../assets/images/blogs/pp141/resolvedselector.png";
import elementstate from "../../../../assets/images/blogs/pp141/elementstate.png";
import containerquerystylepanel from "../../../../assets/images/docs/containerquerystylepanel.png";
import measuredistance2 from "../../../../assets/videos/measure-distance2.mp4";
import colorpickercontrast from "../../../../assets/videos/colorpickercontrast.mp4";
import * as React from 'react';
export default {
  layertree,
  resolvedselector,
  elementstate,
  containerquerystylepanel,
  measuredistance2,
  colorpickercontrast,
  React
};