import { FieldSizingExample, FieldSizingTextareaExample, FieldSizingSelectExample, ReactExamples, GridExample } from "../../../../src/components/fieldsizing/examples";
import contentimg from "../../../../assets/images/blogs/field-sizing/content-editor.png";
import panesizeimg from "../../../../assets/images/blogs/field-sizing/size.png";
import * as React from 'react';
export default {
  FieldSizingExample,
  FieldSizingTextareaExample,
  FieldSizingSelectExample,
  ReactExamples,
  GridExample,
  contentimg,
  panesizeimg,
  React
};