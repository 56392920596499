import React from 'react';
import css from 'astroturf';

const Flask = () => (
  <>
    <div className={styles.isolation}>
      <div className={styles.ball1}></div>
      <div className={styles.ball2}></div>
      <div className={styles.ball3}></div>
      <div className={styles.ball4}></div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.flask}
      width="128"
      height="128"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="rgb(16, 125, 181)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 3l6 0" />
      <path d="M10 9l4 0" />
      <path d="M10 3v6l-8 11a.7 .7 0 0 0 .5 1h19a.7 .7 0 0 0 .5 -1l-8 -11v-6" />
    </svg>
  </>
);

export default Flask;

const styles = css`
  .flask {
    position: relative;
    z-index: 1;
  }
  @keyframes ball1 {
    0% {
      filter: blur(0px);
      opacity: 0;
      transform: translate(-50%, 0%) scale(0);
    }
    50% {
      opacity: 0.8;
      filter: blur(2px);
      transform: translate(-25%, -75%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -200%) scale(0);
      filter: blur(10px);
    }
  }

  @keyframes ball2 {
    0% {
      opacity: 0;
      transform: translate(-50%, 0%) scale(0);
    }
    50% {
      opacity: 1;
      filter: blur(2px);
      transform: translate(-100%, -100%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -200%) scale(0);
      filter: blur(10px);
    }
  }

  @keyframes ball4 {
    0% {
      opacity: 0;
      transform: translate(-50%, 0%) scale(0);
    }
    50% {
      opacity: 1;
      filter: blur(2px);
      transform: translate(-75%, -75%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -200%) scale(0);
      filter: blur(10px);
    }
  }

  @keyframes ball3 {
    0% {
      filter: blur(0px);
      opacity: 0;
      transform: translate(-50%, 0%) scale(0);
    }
    50% {
      opacity: 0.8;
      filter: blur(2px);
      transform: translate(-33%, -75%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -200%) scale(0);
      filter: blur(10px);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .ball1 {
      --size: 40px;
      animation: ball1 3.4s infinite linear;
      position: absolute;
      width: var(--size);
      height: var(--size);
      border-radius: var(--size);
      background: rgb(16, 125, 181);
      box-shadow: 0 0 2px rgb(16, 125, 181);
      left: 100px;
    }

    .ball2 {
      --size: 30px;
      animation: ball2 2.5s -0.33s infinite linear;
      position: absolute;
      width: var(--size);
      height: var(--size);
      border-radius: var(--size);
      background: rgb(16, 125, 151);
      left: 100px;
    }

    .ball3 {
      --size: 35px;
      animation: ball3 2s -1.2s infinite linear;
      position: absolute;
      width: var(--size);
      height: var(--size);
      border-radius: var(--size);
      background: rgb(16, 165, 181);
      left: 100px;
    }
    .ball4 {
      --size: 45px;
      animation: ball4 4s -1.8s infinite linear;
      position: absolute;
      width: var(--size);
      height: var(--size);
      border-radius: var(--size);
      background: rgb(126, 185, 151);
      left: 100px;
    }

    .isolation {
      isolation: isolate;
    }
    .ball1,
    .ball2,
    .ball3,
    .ball4 {
      mix-blend-mode: plus-lighter;
    }
    @media (min-width: 30rem) {
      .ball1,
      .ball2,
      .ball3,
      .ball4 {
        left: 50%;
      }
    }
  }
`;
