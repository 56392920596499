import customheaders from "../../../../assets/images/blogs/pp10/customheaders.png";
import colorblindness from "../../../../assets/images/blogs/pp10/colorblindness.png";
import screenshotbutton from "../../../../assets/images/blogs/pp10/screenshotbutton.png";
import humans from "../../../../assets/images/blogs/pp10/humans.png";
import format from "../../../../assets/images/blogs/pp10/format.png";
import panel from "../../../../assets/images/blogs/pp10/panel.png";
import highlightnodes from "../../../../assets/images/blogs/pp10/highlightnodes.mp4";
import emoji from "../../../../assets/images/blogs/pp10/emoji.mp4";
import measure from "../../../../assets/images/blogs/pp10/measure.mp4";
import elementscreenshot from "../../../../assets/images/blogs/pp10/elementscreenshot.mp4";
import duplicate from "../../../../assets/images/blogs/pp10/duplicate.mp4";
import navsync from "../../../../assets/images/blogs/pp10/navsync.mp4";
import * as React from 'react';
export default {
  customheaders,
  colorblindness,
  screenshotbutton,
  humans,
  format,
  panel,
  highlightnodes,
  emoji,
  measure,
  elementscreenshot,
  duplicate,
  navsync,
  React
};