import ShortcutDisplay from "../../../../src/components/ShortcutDisplay";
import webstorm1 from "../../../../assets/images/docs/webstorm/webstorm1.png";
import webstorm1b from "../../../../assets/images/docs/webstorm/webstorm1b.png";
import webstorm2 from "../../../../assets/images/docs/webstorm/webstorm2.png";
import webstorm3 from "../../../../assets/images/docs/webstorm/webstorm3.png";
import webstorm4 from "../../../../assets/images/docs/webstorm/webstorm4.png";
import webstorm5 from "../../../../assets/images/docs/webstorm/webstorm5.png";
import webstorm6 from "../../../../assets/images/docs/webstorm/webstorm6.png";
import webstorm7 from "../../../../assets/images/docs/webstorm/webstorm7.png";
import * as React from 'react';
export default {
  ShortcutDisplay,
  webstorm1,
  webstorm1b,
  webstorm2,
  webstorm3,
  webstorm4,
  webstorm5,
  webstorm6,
  webstorm7,
  React
};