import { css } from 'astroturf';
import React, { useLayoutEffect, useState } from 'react';
import { Upload } from 'react-bytesize-icons';

const styles = css`
  .workspaceWrapper {
    display: flex;
    flex-direction: column;
    width: max(100%, fit-content);
    margin: 0 auto;
    background: #ffffff;
    border-radius: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
  }
  .workspaceWrapper .panelist {
    position: relative;
    width: auto;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    min-height: 225px;
    gap: 1rem;
    overflow: hidden;

    & li {
      margin: 0;
      text-align: center;
      line-height: 2;
    }

    & div {
      margin-bottom: 4px;
      background: rgba(0, 70, 150, 0.1);
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.02) 0px 0px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      min-width: fit-content;

      & i {
        font-size: 0.8em;
        display: block;
        opacity: 0.8;
        font-style: normal;
      }
    }
  }

  .buttonContainer {
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    background: rgb(0, 121, 177);
    font-weight: 500;
    line-height: 2.25;
    color: #fff;
    text-align: center;
    position: relative;
    paddin: 0 1rem;

    @media (min-width: 800px) {
      padding: 0 0 0 2rem;
    }

    & .open {
      color: #fff;
      line-height: 1.25;
      padding: 0.5rem;
      white-space: nowrap;
      transition: 0.25s ease-out all;
      margin: 0;
      margin-left: auto;
      font-weight: bold;
      display: block;

      @media (min-width: 800px) {
        background: linear-gradient(90deg, rgb(0, 121, 177), #67b26f);
        position: absolute;
        right: 0;
        top: 0;
      }

      &:hocus {
        background: linear-gradient(135deg, #0a1530, #0a1530);
        &:after {
          display: none;
        }
      }

      & svg {
        vertical-align: middle;
      }
    }
  }
`;

const WorkspacePreviewBreakpoints = ({ sizes, name, download }) => {
  const [openVisible, setOpenVisible] = useState(true);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (window.__polypane) {
        setOpenVisible(false);
      }
    }, 200);
  }, []);

  const open = `https://polypane.app/exported-panes/?panesizes=${encodeURIComponent(
    JSON.stringify(
      sizes.map((item) => ({
        width: item.width,
        title: item.name,
      }))
    )
  )}`;

  return (
    <div className={styles.workspaceWrapper}>
      <div className={styles.buttonContainer}>
        {name}{' '}
        {openVisible && (
          <a className={styles.open} href={open}>
            Open in Polypane <Upload width={20} height={20} />
          </a>
        )}
      </div>
      <ul className={[styles.panelist, 'panelist'].join(' ')}>
        {sizes.map((item) => (
          <li key={item.width}>
            <div
              style={{
                width: `${item.width / 7}px`,
                height: `100%`,
              }}
            >
              {item.name}
              <i>{!item.hideWidth ? <>{item.width}px</> : <>&nbsp;</>}</i>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WorkspacePreviewBreakpoints;
