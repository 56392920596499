import outline from "../../../../assets/images/docs/outline.png";
import outlineheadings from "../../../../assets/images/docs/outline-headings.png";
import landmarks from "../../../../assets/images/blogs/pp12/landmarks.png";
import a11ytree from "../../../../assets/images/blogs/pp201/a11ytree.png";
import * as React from 'react';
export default {
  outline,
  outlineheadings,
  landmarks,
  a11ytree,
  React
};