import cookie1 from "../../../../assets/images/docs/cookie1.png";
import cookie2 from "../../../../assets/images/docs/cookie2.png";
import cookie3 from "../../../../assets/images/docs/cookie3.png";
import cookie4 from "../../../../assets/images/docs/cookie4.png";
import storage1 from "../../../../assets/images/docs/storage1.png";
import * as React from 'react';
export default {
  cookie1,
  cookie2,
  cookie3,
  cookie4,
  storage1,
  React
};