import { css } from 'astroturf';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';

const config = {
  angle: 100,
  spread: 120,
  startVelocity: '40',
  elementCount: '150',
  dragFriction: '0.1',
  duration: 8000,
  stagger: '1',
  width: '16px',
  height: '16px',
  colors: ['#b51010', '#b5107d', '#107db5', '#10b57d', '#7d10b5', '#7db510'],
  // colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const styles = css`
  .buttonContainer {
    margin-top: -5rem;
    z-index: 1;
    display: flex;
    gap: 1rem;
  }
  .button {
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    font-weight: 600;
    border: 0;
    width: 120px;
    border-radius: 0.5rem;
    padding: 0 1rem;
    font-size: 1em;
    line-height: 2;
    color: #fff;
    outline: none;
    transition: 0.3s ease-out background;
    box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px 0, rgba(0, 0, 0, 0.03) 0px 0px 8px 0,
      rgba(0, 0, 0, 0.1) 0px 20px 30px 0;

    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.2);
    }

    & svg {
      display: block;
      stroke: #fff;
      margin-right: 0.25rem;
      width: 20px;
      height: 20px;
      flex-basis: 20px;
    }
  }
`;

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const Copy = (props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {});

  const copyCode = (e) => {
    e.preventDefault();
    copyToClipboard(props.code);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);

    // props.code
  };

  return (
    <div className={styles.buttonContainer}>
      <button className={styles.button} type="button" onClick={copyCode}>
        {copied ? '🎉 Copied!' : 'Copy CSS'}
      </button>
      <Confetti active={copied} config={config} />
      <a href={props.codepen} target="_blank" rel="noopener" className={styles.button}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-brand-codepen"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 15l9 6l9 -6l-9 -6l-9 6" />
          <path d="M3 9l9 6l9 -6l-9 -6l-9 6" />
          <line x1="3" y1="9" x2="3" y2="15" />
          <line x1="21" y1="9" x2="21" y2="15" />
          <line x1="12" y1="3" x2="12" y2="9" />
          <line x1="12" y1="15" x2="12" y2="21" />
        </svg>
        Codepen
      </a>
    </div>
  );
};

export default Copy;
