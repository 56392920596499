import colorblindnessimg from "../../../../assets/images/colorblindness.png";
import active from "../../../../assets/images/blogs/colorblindness/active.png";
import debugtool from "../../../../assets/images/blogs/colorblindness/debug-tool.png";
import simulatorImage from "../../../../assets/images/blogs/colorblindness/simulators.png";
import * as React from 'react';
export default {
  colorblindnessimg,
  active,
  debugtool,
  simulatorImage,
  React
};