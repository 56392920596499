import extensions2 from "../../../../assets/images/docs/extensions2.png";
import extensions4 from "../../../../assets/images/docs/activepaneicon.png";
import visbug from "../../../../assets/images/blogs/pp13/visbug.png";
import nesting from "../../../../assets/images/blogs/pp13/nesting.png";
import classlist from "../../../../assets/images/blogs/pp13/classlist.mp4";
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import findelement from "../../../../assets/images/docs/findelement.png";
import layoutalign from "../../../../assets/images/docs/layout-align.png";
import contentchaos from "../../../../assets/images/blogs/pp13/contentchaos.png";
import inpattribution from "../../../../assets/images/docs/inp-attribution.png";
import * as React from 'react';
export default {
  extensions2,
  extensions4,
  visbug,
  nesting,
  classlist,
  ShortCutDisplay,
  findelement,
  layoutalign,
  contentchaos,
  inpattribution,
  React
};