import activeportal from "../../../../assets/images/docs/portal/active.png";
import network1 from "../../../../assets/images/docs/portal/network1.png";
import network2 from "../../../../assets/images/docs/portal/network2.png";
import public1 from "../../../../assets/images/docs/portal/public1.png";
import portal from "../../../../assets/videos/portal.mp4";
import * as React from 'react';
export default {
  activeportal,
  network1,
  network2,
  public1,
  portal,
  React
};