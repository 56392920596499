import extensions1 from "../../../../assets/images/docs/extensions1.png";
import extensions2 from "../../../../assets/images/docs/extensions2.png";
import extensions3 from "../../../../assets/images/docs/extension-manager.png";
import extensions4 from "../../../../assets/images/docs/activepaneicon.png";
import browseext1 from "../../../../assets/images/docs/browse/ext1.png";
import * as React from 'react';
export default {
  extensions1,
  extensions2,
  extensions3,
  extensions4,
  browseext1,
  React
};