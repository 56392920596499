import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import topbar from "../../../../assets/images/docs/console-top-bar.png";
import popups from "../../../../assets/images/docs/consolepopups.png";
import network from "../../../../assets/images/blogs/pp131/network.png";
import consoleTable from "../../../../assets/images/docs/console/console-table.mp4";
import consoleSearch from "../../../../assets/images/docs/console/search.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  topbar,
  popups,
  network,
  consoleTable,
  consoleSearch,
  React
};