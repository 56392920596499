import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .wrap {
    font-family: monospace;
    color: #466;
  }
  .swatch {
    width: 1em;
    height: 1em;
    vertical-align: baseline;
    border-radius: 4px;
    margin-right: 0.25rem;
    display: inline-block;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px inset;
  }
`;

export default ({ color }) => (
  <span className={styles.wrap}>
    <span className={styles.swatch} style={{ backgroundColor: color }} />
    {color.toLowerCase()}
  </span>
);
