import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import screenshotoptions from "../../../../assets/images/docs/screenshot-options.png";
import swatch from "../../../../assets/images/blogs/pp11/swatch.png";
import overview from "../../../../assets/images/blogs/pp11/overview.png";
import boxmodel from "../../../../assets/images/blogs/pp11/boxmodel.png";
import boxmodel2 from "../../../../assets/images/blogs/pp11/boxmodel2.png";
import eleminfo from "../../../../assets/images/blogs/pp11/eleminfo.png";
import elempanelcolor from "../../../../assets/images/blogs/pp11/elempanelcolor.png";
import slack from "../../../../assets/images/blogs/pp11/slack.png";
import flexindicator from "../../../../assets/images/blogs/pp11/flexindicator.png";
import sniper from "../../../../assets/images/blogs/pp11/sniper.jpg";
import debug from "../../../../assets/images/blogs/pp11/debug.png";
import smalltext from "../../../../assets/images/blogs/pp11/smalltext.png";
import focus from "../../../../assets/images/blogs/pp11/focus.mp4";
import contrast from "../../../../assets/images/blogs/pp11/contrast.mp4";
import target from "../../../../assets/images/blogs/pp11/target.mp4";
import inspect from "../../../../assets/images/blogs/pp11/inspect.mp4";
import path from "../../../../assets/images/blogs/pp11/path.mp4";
import setup3 from "../../../../assets/images/docs/setup/3.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  screenshotoptions,
  swatch,
  overview,
  boxmodel,
  boxmodel2,
  eleminfo,
  elempanelcolor,
  slack,
  flexindicator,
  sniper,
  debug,
  smalltext,
  focus,
  contrast,
  target,
  inspect,
  path,
  setup3,
  React
};