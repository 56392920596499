import classeditor from "../../../../assets/images/docs/elements/classeditor.png";
import refimg from "../../../../assets/images/docs/referenceimage.mp4";
import imageslider from "../../../../assets/images/docs/referenceimage/image-slider.mp4";
import startingStyleVid from "../../../../assets/videos/blogs/pp20/startingstyle.mp4";
import a11ypanel from "../../../../assets/images/docs/a11ypanel.png";
import navigator from "../../../../assets/images/docs/navigator.png";
import ShortcutDisplay from "../../../../src/components/ShortcutDisplay";
import addclasses from "../../../../assets/images/docs/elements/classeditor-suggestions.png";
import networkEmulation from "../../../../assets/images/docs/network-emulation.png";
import mediaEmulation from "../../../../assets/images/docs/media-emulation.png";
import fontface from "../../../../assets/images/blogs/devtoolsfeats/fontface.png";
import htmlvalidation from "../../../../assets/images/docs/htmlvalidation.png";
import nonetwork from "../../../../assets/images/blogs/devtoolsfeats/nonetwork.png";
import debugpanel from "../../../../assets/images/blogs/pp14/debug.png";
import delay from "../../../../assets/images/blogs/pp12/delay.mp4";
import app4 from "../../../../assets/images/hero/app4.png";
import * as React from 'react';
export default {
  classeditor,
  refimg,
  imageslider,
  startingStyleVid,
  a11ypanel,
  navigator,
  ShortcutDisplay,
  addclasses,
  networkEmulation,
  mediaEmulation,
  fontface,
  htmlvalidation,
  nonetwork,
  debugpanel,
  delay,
  app4,
  React
};