import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Heart } from 'react-bytesize-icons';

const styles = css`
  .svg {
    fill: currentColor;
    height: 1rem;
    width: 1rem;
    vertical-align: baseline;
    transform: translateY(2px);
    margin-right: 4px;
  }

  .images {
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 150px;

    &.inHero {
      @media (min-width: 1024px) {
        justify-content: flex-start;
      }
    }

    & img {
      max-height: 150px;

      @media (max-width: 480px) {
        max-height: 100px;
      }
    }
    & svg.svgHeartBeatAnim {
      margin: 0 1rem;
      fill: inherit;
      width: 3rem;
      height: 3rem;
    }
  }
`;

function BeatingHeart(props) {
  return (
    <div
      className={[styles.images, props.inHero ? styles.inHero : ''].join(' ')}
      style={{ maxHeight: '150px', fill: props.color || '#107db5' }}
    >
      <StaticImage
        src="../../assets/images/icon.png"
        alt=""
        objectFit="contain"
        placeholder="none"
        width={150}
        formats={['PNG']}
      />
      <Heart
        className={[styles.svgHeartBeatAnim, 'svgHeartBeatAnim'].join(' ')}
        strokeWidth="12.5%"
        color={props.color || '#107db5'}
      />
      {props.children}
    </div>
  );
}

export default BeatingHeart;
