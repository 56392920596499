import ShortcutDisplay from "../../../../src/components/ShortcutDisplay";
import step1 from "../../../../assets/images/docs/logi/step1.png";
import step2 from "../../../../assets/images/docs/logi/step2.png";
import step3 from "../../../../assets/images/docs/logi/step3.png";
import step4 from "../../../../assets/images/docs/logi/step4.png";
import step5 from "../../../../assets/images/docs/logi/step5.png";
import * as React from 'react';
export default {
  ShortcutDisplay,
  step1,
  step2,
  step3,
  step4,
  step5,
  React
};