import bbc from "../../../../assets/images/blogs/xpreview/bbc.png";
import salma1 from "../../../../assets/images/blogs/xpreview/salma1.png";
import salma2 from "../../../../assets/images/blogs/xpreview/salma2.png";
import x680 from "../../../../assets/images/blogs/xpreview/680.png";
import x1280 from "../../../../assets/images/blogs/xpreview/1280.png";
import x1366 from "../../../../assets/images/blogs/xpreview/1366.png";
import xpreviews from "../../../../assets/images/blogs/xpreview/xpreviews.png";
import * as React from 'react';
export default {
  bbc,
  salma1,
  salma2,
  x680,
  x1280,
  x1366,
  xpreviews,
  React
};