import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BeatingHeart from './BeatingHeart';

function Github() {
  return (
    <BeatingHeart>
      <StaticImage
        src="../../assets/images/github-student-backpack.png"
        alt=""
        objectFit="contain"
        placeholder="none"
        width={140}
      />
    </BeatingHeart>
  );
}

export default Github;
