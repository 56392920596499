module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://polypane.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"posts":"/home/kilian/Workspace/Firstversionist/polypane/site/src/templates/post.jsx","docs":"/home/kilian/Workspace/Firstversionist/polypane/site/src/templates/doc.jsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":690,"sizeByPixelDensity":true}},{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"100","icon":false}},{"resolve":"gatsby-remark-responsive-iframe"},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files",{"resolve":"@weknow/gatsby-remark-codepen","options":{"theme":"dark","height":265}}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/kilian/Workspace/Firstversionist/polypane/site"},
    },{
      plugin: require('../node_modules/@pauliescanlon/gatsby-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Polypane","short_name":"Polypane","start_url":"/","background_color":"#ffffff","theme_color":"#208dc5","display":"minimal-ui","icon":"assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5df5528946772487a6ab462ff2125f26"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
