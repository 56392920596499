import imageslider from "../../../../assets/images/docs/referenceimage/image-slider.mp4";
import trackfocus from "../../../../assets/images/docs/debugtools/track-focus.mp4";
import scoping from "../../../../assets/images/docs/elements/scoping.png";
import classeditor from "../../../../assets/images/docs/elements/classeditor.png";
import classeditorSuggestions from "../../../../assets/images/docs/elements/classeditor-suggestions.png";
import navigator from "../../../../assets/images/docs/navigator.png";
import narrow from "../../../../assets/images/blogs/pp17/narrow.png";
import consoleTable from "../../../../assets/images/docs/console/console-table.mp4";
import addressbar from "../../../../assets/images/blogs/pp17/addressbar.png";
import metasuggestions from "../../../../assets/images/blogs/pp17/metasuggestions.png";
import twittertitle from "../../../../assets/images/blogs/pp17/twittertitle.png";
import canonical from "../../../../assets/images/blogs/pp17/canonical.png";
import * as React from 'react';
export default {
  imageslider,
  trackfocus,
  scoping,
  classeditor,
  classeditorSuggestions,
  navigator,
  narrow,
  consoleTable,
  addressbar,
  metasuggestions,
  twittertitle,
  canonical,
  React
};