import flexgrid from "../../../../assets/images/blogs/pp131/flexgrid.png";
import copyselector from "../../../../assets/images/blogs/pp131/copyselector.png";
import network from "../../../../assets/images/blogs/pp131/network.png";
import googleserp from "../../../../assets/images/blogs/pp131/googleserp.png";
import apca from "../../../../assets/images/blogs/pp131/apca.png";
import measuretext from "../../../../assets/images/docs/measuretext.jpg";
import * as React from 'react';
export default {
  flexgrid,
  copyselector,
  network,
  googleserp,
  apca,
  measuretext,
  React
};