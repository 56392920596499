import React from 'react';

import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Link = ({ children, to, activeClassName, getProps, ...other }) => {
  // @TODO this is a hack to fix menu closing for pages that are secretly the same.
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <OutboundLink href={to} {...other}>
      {children}
    </OutboundLink>
  );
};

export default Link;
