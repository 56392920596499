import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import setup1 from "../../../../assets/images/docs/setup/1.png";
import setup2 from "../../../../assets/images/docs/setup/2.png";
import setup3 from "../../../../assets/images/docs/setup/3.png";
import setup4 from "../../../../assets/images/docs/setup/4.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  setup1,
  setup2,
  setup3,
  setup4,
  React
};