import panesize1 from "../../../../assets/images/docs/panesize1.png";
import panesize2 from "../../../../assets/images/docs/panesize2.png";
import panesize3 from "../../../../assets/images/docs/panesize3.png";
import panesize4 from "../../../../assets/images/docs/panesize4.png";
import devicepresets from "../../../../assets/images/docs/devicepresets.png";
import paneresize from "../../../../assets/videos/pane-resize.mp4";
import * as React from 'react';
export default {
  panesize1,
  panesize2,
  panesize3,
  panesize4,
  devicepresets,
  paneresize,
  React
};