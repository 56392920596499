import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import cmdbar from "../../../../assets/images/docs/commandbar.png";
import cmdbarlorem from "../../../../assets/images/docs/commandbarlorem.png";
import confetti from "../../../../assets/images/blogs/pp12/confetti.mp4";
import * as React from 'react';
export default {
  ShortCutDisplay,
  cmdbar,
  cmdbarlorem,
  confetti,
  React
};