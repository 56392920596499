import { Photo } from 'react-bytesize-icons';
import refimg from "../../../../assets/images/docs/referenceimage.mp4";
import imageslider from "../../../../assets/images/docs/referenceimage/image-slider.mp4";
import ShortcutDisplay from "../../../../src/components/ShortcutDisplay";
import * as React from 'react';
export default {
  Photo,
  refimg,
  imageslider,
  ShortcutDisplay,
  React
};