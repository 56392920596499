import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import muteaudio from "../../../../assets/images/docs/muteaudio.png";
import adjusttextsize from "../../../../assets/images/docs/adjusttextsize.png";
import basicauth from "../../../../assets/images/docs/basicauth.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  muteaudio,
  adjusttextsize,
  basicauth,
  React
};