import { css } from 'astroturf';
import React from 'react';
import Link from '../../components/link';
import Copy from './_Copy';

// import the Prism package

const styles = css`
  .exampleTitle {
    font-size: 1.5rem !important;
    color: #000 !important;
    text-align: center;

    & a {
      box-shadow: none;
      &::after {
        display: none;
      }
    }
  }

  .tags {
    text-align: center;
    & span {
      vertical-align: middle;
      background: rgb(30, 30, 63);
      color: #fff;
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
      border-radius: 2rem;
      padding: 0.25rem 0.5rem;
      margin: 0 0 0 1rem;
    }
  }
  .example {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;

    & > div {
      flex: 1 1 50%;
      padding-bottom: 2rem;
    }

    @media (min-width: 960px) {
      flex-direction: row;
      & > div {
        padding-bottom: 0;
      }
    }
  }

  .container {
    padding: 0;
    position: relative;
    display: grid;
    place-items: center;
  }

  .perspective {
    width: 30vmin;
    height: 25vmin;
  }

  .code {
    border-radius: 4px;
    width: 100%;
    font-size: 12px;
    font-family: monospace;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;

    & > div {
      width: 100%;
      margin-bottom: 0;
    }
    & pre[class] {
      width: 100%;
      margin: 0;
    }
    & pre code {
      font-size: 12px;
      white-space: pre-wrap;
    }
  }
`;

const Example = (props) => (
  <>
    <h2 className={styles.exampleTitle}>
      3D Transform {props.index ? <>#{props.index}</> : null}{' '}
      <small>
        by <Link to={props.link}>{props.name}</Link>
      </small>
    </h2>
    <div className={styles.tags}>
      {(props.code || '').indexOf(':hover') > 1 ? <span>hover</span> : null}
      {(props.code || '').indexOf('box-shadow') > 1 ? <span>shadow</span> : null}
    </div>
    <div className={styles.example}>
      <div className={styles.container}>
        <div className={[styles.perspective, props.cssStyle].join(' ')} />
      </div>
      <div className={styles.container}>
        <div className={styles.code}>
          <div className="gatsby-highlight">
            <pre className="language-css">
              <code>{props.code}</code>
            </pre>
          </div>
        </div>
        <Copy code={props.code} codepen={props.codepen} />
      </div>
    </div>
  </>
);

export default Example;
