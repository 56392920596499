import dir1 from "../../../../assets/images/blogs/l10n/dir1.png";
import dir from "../../../../assets/images/blogs/l10n/dir.mp4";
import locale from "../../../../assets/images/blogs/l10n/locale.png";
import locale2 from "../../../../assets/images/blogs/l10n/locale2.png";
import contentmore from "../../../../assets/images/blogs/l10n/contentmore.png";
import contentless from "../../../../assets/images/blogs/l10n/contentless.png";
import measuretext from "../../../../assets/images/docs/measuretext.jpg";
import * as React from 'react';
export default {
  dir1,
  dir,
  locale,
  locale2,
  contentmore,
  contentless,
  measuretext,
  React
};