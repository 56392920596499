import scrollarea from "../../../../assets/videos/blogs/pp211/scroll.mp4";
import scrollarea2 from "../../../../assets/videos/blogs/pp211/scroll2.mp4";
import loaderror from "../../../../assets/images/blogs/pp211/loaderror.png";
import subgrid from "../../../../assets/images/blogs/pp211/subgrid.png";
import * as React from 'react';
export default {
  scrollarea,
  scrollarea2,
  loaderror,
  subgrid,
  React
};