import rulers from "../../../../assets/images/docs/rulers.png";
import guides from "../../../../assets/images/docs/guides.png";
import grid from "../../../../assets/images/docs/grid.png";
import dotgrid from "../../../../assets/images/docs/dotgrid.png";
import columns from "../../../../assets/images/docs/columns.png";
import rows from "../../../../assets/images/docs/rows.png";
import measuredistance2 from "../../../../assets/videos/measure-distance2.mp4";
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import * as React from 'react';
export default {
  rulers,
  guides,
  grid,
  dotgrid,
  columns,
  rows,
  measuredistance2,
  ShortCutDisplay,
  React
};