import startingStyleVid from "../../../../assets/videos/blogs/pp20/startingstyle.mp4";
import missinga11yname from "../../../../assets/images/blogs/pp20/missing.png";
import repeatinga11yname from "../../../../assets/images/blogs/pp20/repeating.png";
import checkstatus from "../../../../assets/images/blogs/pp20/checkstatus.png";
import openfolder from "../../../../assets/images/blogs/pp20/openfolder.png";
import urlissue from "../../../../assets/images/blogs/pp20/urlissue.png";
import visualcontent from "../../../../assets/images/blogs/pp20/visualcontent.png";
import repeatingoutline from "../../../../assets/images/blogs/pp20/outline.png";
import dashesineditor from "../../../../assets/images/blogs/pp20/dotted.png";
import * as React from 'react';
export default {
  startingStyleVid,
  missinga11yname,
  repeatinga11yname,
  checkstatus,
  openfolder,
  urlissue,
  visualcontent,
  repeatingoutline,
  dashesineditor,
  React
};