import { Options } from 'react-bytesize-icons';
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import navsync from "../../../../assets/images/blogs/pp10/navsync.mp4";
import mediaEmulation from "../../../../assets/images/docs/media-emulation.png";
import emulationEmulation from "../../../../assets/images/docs/emulation-emulation.png";
import networkEmulation from "../../../../assets/images/docs/network-emulation.png";
import tabpane from "../../../../assets/images/blogs/pp14/tabpane.png";
import * as React from 'react';
export default {
  Options,
  ShortCutDisplay,
  navsync,
  mediaEmulation,
  emulationEmulation,
  networkEmulation,
  tabpane,
  React
};