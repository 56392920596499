import ShortcutDisplay from "../../../../src/components/ShortcutDisplay";
import webvitals from "../../../../assets/images/docs/webvitals/popup.png";
import capo from "../../../../assets/images/docs/meta/capo.png";
import screenshotpadding from "../../../../assets/images/docs/screenshots/padding.png";
import autodarkmode from "../../../../assets/images/docs/autodarkmode.png";
import consolesearch from "../../../../assets/images/docs/console/search.png";
import scrollsnap from "../../../../assets/images/docs/elements/scrollsnap.jpg";
import langwarn from "../../../../assets/images/blogs/pp19/langwarn.png";
import copyhtml from "../../../../assets/images/blogs/pp19/copyhtml.png";
import elementspanelupdate from "../../../../assets/images/blogs/pp19/elementspanelupdate.mp4";
import consolestyle from "../../../../assets/images/blogs/pp19/consolestyle.png";
import colorscheme from "../../../../assets/images/blogs/pp19/colorscheme.png";
import outlinelinks from "../../../../assets/images/blogs/pp19/outline.png";
import shiftclickcolor from "../../../../assets/images/docs/elements/shiftclickcolor.mp4";
import colorpickerfromelement from "../../../../assets/images/docs/colorpickerfromelement.mp4";
import linkedin from "../../../../assets/images/blogs/pp19/linkedin.png";
import requiredtags from "../../../../assets/images/blogs/pp19/requiredtags.png";
import reloadmenu from "../../../../assets/images/blogs/pp19/reloadmenu.png";
import * as React from 'react';
export default {
  ShortcutDisplay,
  webvitals,
  capo,
  screenshotpadding,
  autodarkmode,
  consolesearch,
  scrollsnap,
  langwarn,
  copyhtml,
  elementspanelupdate,
  consolestyle,
  colorscheme,
  outlinelinks,
  shiftclickcolor,
  colorpickerfromelement,
  linkedin,
  requiredtags,
  reloadmenu,
  React
};