import ShortCutDisplay from "../../../../src/components/ShortcutDisplay.jsx";
import mastodon from "../../../../assets/images/blogs/pp15/mastodon.png";
import pp15nesting from "../../../../assets/images/blogs/pp15/nesting.png";
import pp15layers from "../../../../assets/images/blogs/pp15/csslayers.png";
import pp15layoutdebugging from "../../../../assets/images/blogs/pp15/layoutdebugging.png";
import pp15macos from "../../../../assets/images/blogs/pp15/macos.png";
import pp15platform from "../../../../assets/images/blogs/pp15/platform-emulation.mp4";
import pp15browse from "../../../../assets/images/blogs/pp15/browse.jpg";
import browsesearch1 from "../../../../assets/images/docs/browse/search1.png";
import browse1 from "../../../../assets/images/docs/browse/browse1.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  mastodon,
  pp15nesting,
  pp15layers,
  pp15layoutdebugging,
  pp15macos,
  pp15platform,
  pp15browse,
  browsesearch1,
  browse1,
  React
};