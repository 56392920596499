import { css } from 'astroturf';
import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';

import AutosizeInput from 'react-18-input-autosize';
import TextareaAutosize from 'react-autosize-textarea';

/* TODO
  generalize main UI (not buttonbar)

*/

const styles = css`
  .offsetexample {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    border-radius: 8px;
    overflow: hidden;
    background: #f7faff;

    @media (max-width: 40rem) {
      grid-template-columns: 1fr;
      grid-template-rows:  repeat(2, minmax(200px, 1fr));
    }
  }


  .code {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    background-color: #f7faff;

    & pre {
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      min-width: 1000px;
      background: transparent !important;
    }

    & > div > div {
      margin: 0 !important;
      width: 100%;
    }

    & .block {
      position: relative;
      overflow: hidden;
      max-height: 20rem;
      display: block;
      border-radius: 8px;
      padding: 1rem;
      background: #1e1e3f;
      color: #fff;
      border-radius: 8px;

      & > div {
        overflow: visible;
      }

      & > div,
      & textarea {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        position: absolute;
        inset: 1rem;
      }
    }

    & textarea {
      border: 0;
      z-index: 2;

      background: none;
      color: transparent;

      resize: none;

      direction: ltr;
      text-align: left;
      overflow: auto;
      white-space: nowrap;
      word-spacing: normal;
      word-break: normal;
      tab-size: 4;
      hyphens: none;
      font-family: 'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.5px;
      outline: none;
      caret-color: #fff;
    }

    & textarea::selection {
      background: #fff;
      color: #000;
    }
  }

  .block::before,
  .result::before {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0.125rem 0.5rem 0 0.25rem;
    background: #1e1e3f;
    color: #fff;
    font-size: 0.6rem;
    line-height: 1.25;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid #f7faff;
    right: 0;
    border-radius: 0 0 0 0.25rem;
    border-width: 0 0 2px 2px;
    pointer-events: none;
  }
  .block:global(:has(pre.language-html))::before {
    content: 'html';
  }
  .block:global(:has(pre.language-css))::before {
    content: 'css';
    top: 2px;
  }

  .result {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: #1e1e3f;

    & iframe {
      background-color: #1e1e3f;
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }

    &::before {
      z-index: 1;
      content: 'result';
    }
  }
`;

const htmlInit = `<input
  type="text"
  placeholder="input"
  value="this sizes to its content"
/>`;

const cssCodeInit = `input {
  field-sizing: content;
}`;

const Editor = ({
  html,
  cssCode,
  setHtml,
  setCSS,
  htmlHeight,
  cssHeight,
  htmlOffset,
  setHtmlOffset,
  cssOffset,
  setCssOffset,
  combination,
}) => {
  const iframe = useRef(null);

  useEffect(() => {
    Prism.highlightAll();
    iframe.current.srcdoc = combination;
  }, [cssCode, html]);

  return (
    <div className={styles.offsetexample}>
      <div className={styles.code}>
        <div className={styles.block} style={{ height: htmlHeight }}>
          <div className="gatsby-highlight" style={{ translate: `-${htmlOffset.x}px -${htmlOffset.y}px` }}>
            <pre className="language-html">
              <code>{html}</code>
            </pre>
          </div>
          <textarea
            spellCheck={false}
            value={html}
            onScroll={(e) => setHtmlOffset({ x: e.target.scrollLeft, y: e.target.scrollTop })}
            onChange={(e) => setHtml(e.target.value)}
          />
        </div>
        <div className={styles.block} style={{ height: cssHeight }}>
          <div className="gatsby-highlight" style={{ translate: `-${cssOffset.x}px -${cssOffset.y}px` }}>
            <pre className="language-css">
              <code>{cssCode}</code>
            </pre>
          </div>
          <textarea
            spellCheck={false}
            value={cssCode}
            onScroll={(e) => setCssOffset({ x: e.target.scrollLeft, y: e.target.scrollTop })}
            onChange={(e) => setCSS(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.result}>
        <iframe ref={iframe} title="result" sandbox="allow-scripts" />
      </div>
    </div>
  );
};

export const FieldSizingExample = () => {
  const [html, setHtml] = React.useState(htmlInit);
  const [cssCode, setCSS] = React.useState(cssCodeInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          min-height: 100vh;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        .parent {
          background: #3e3e5f;
          padding: 1rem;
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={160}
        cssHeight={110}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};

const htmlSelectInit = `<select>
  <option>Short Option</option>
  <option>Longer Option</option>
  <option>Extra Long Option With Many Words</option>
</select>
`;

const cssCodeSelectInit = `select {
  field-sizing: content;
}`;

export const FieldSizingSelectExample = () => {
  const [html, setHtml] = React.useState(htmlSelectInit);
  const [cssCode, setCSS] = React.useState(cssCodeSelectInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          min-height: 100vh;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        .parent {
          background: #3e3e5f;
          padding: 1rem;
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={160}
        cssHeight={110}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};

const htmlTAInit = `<textarea>
Here is a
Multiline
Textarea
</textarea>`;

const cssCodeTAInit = `textarea {
  field-sizing: content;
  width: 200px;
}`;

export const FieldSizingTextareaExample = () => {
  const [html, setHtml] = React.useState(htmlTAInit);
  const [cssCode, setCSS] = React.useState(cssCodeTAInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          min-height: 100vh;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        .parent {
          background: #3e3e5f;
          padding: 1rem;
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={160}
        cssHeight={130}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};

const gridHTML = `<div class="wrap">
  <textarea
    rows="1"
    onInput="this.parentNode.dataset.value = this.value">this textarea autosizes</textarea>
</div>`;

const gridCSS = `.wrap {
  position: relative;
  display: grid;
  width:150px;
  font-size:14px;
}

.wrap::after {
  display: block;
  content: attr(data-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.wrap textarea {
  resize: none;
  overflow: hidden;
}

.wrap::after,
.wrap textarea {
  font:inherit;
  grid-area: 1/1/2/2;
  padding:2px;
}`;

export const GridExample = () => {
  const [html, setHtml] = React.useState(gridHTML);
  const [cssCode, setCSS] = React.useState(gridCSS);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          min-height: 100vh;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        .parent {
          background: #3e3e5f;
          padding: 1rem;
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}


    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={170}
        cssHeight={540}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};

const ReactStyles = css`
  .reactBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    color-scheme: dark;

    @media (max-width: 40rem) {
      grid-template-columns: 1fr;
      grid-template-rows:  repeat(2, minmax(200px, 1fr));
    }
  }

  .innerCode {
    position: relative;
    overflow: hidden;
    max-height: 20rem;
    display: block;
    padding: 1rem;
    background: rgb(30, 30, 63);
    color: rgb(255, 255, 255);
    border-radius: 8px;
    display: grid;
    place-items: center;

    > div {
      padding: 0;
      width: 100%;
      overflow: auto;
      margin: 0;
    }
    pre[class*='language-'] {
      padding: 0;
      margin: 0;
    }
  }

  .innerExample {
    position: relative;
    overflow: hidden;
    max-height: 20rem;
    display: block;
    padding: 1rem;
    background: rgb(30, 30, 63);
    color: rgb(255, 255, 255);
    border-radius: 8px;
    display: grid;
    place-items: center;
  }

  .innerExample > span {
    position: absolute;
    top: 0px;
    z-index: 1;
    padding: 0.125rem 0.5rem 0px 0.25rem;
    background: rgb(30, 30, 63);
    color: rgb(255, 255, 255);
    font-size: 0.6rem;
    line-height: 1.25;
    font-weight: 500;
    text-transform: uppercase;
    border-style: solid;
    border-color: rgb(247, 250, 255);
    border-image: initial;
    right: 0px;
    border-radius: 0px 0px 0px 0.25rem;
    border-width: 0px 0px 2px 2px;
    pointer-events: none;
  }
`;

const reactcode1 = `<AutosizeInput
  value={value}
  onChange={(e) => setValue(e.target.value)}
/>
`;
const reactcode2 = `<TextareaAutosize
  style={{ width: 200 }}
  defaultValue="This textarea autosizes automatically to be as tall as needed"
/>
`;

export const ReactExamples = () => {
  const [value, setValue] = React.useState('This input autosizes');

  return (
    <div className={ReactStyles.reactBox}>
      <div className={ReactStyles.innerCode}>
        <div className="gatsby-highlight">
          <pre className="language-html">
            <code>{reactcode1}</code>
          </pre>
        </div>
      </div>
      <div className={ReactStyles.innerExample}>
        <AutosizeInput name="form-field-name" value={value} onChange={(e) => setValue(e.target.value)} />
      </div>

      <div className={ReactStyles.innerCode}>
        <div className="gatsby-highlight">
          <pre className="language-html">
            <code>{reactcode2}</code>
          </pre>
        </div>
      </div>
      <div className={ReactStyles.innerExample}>
        <TextareaAutosize
          style={{ width: 200 }}
          defaultValue="This textarea autosizes automatically to be as tall as needed"
        />
      </div>
    </div>
  );
};
