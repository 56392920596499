import navsync from "../../../../assets/images/blogs/pp10/navsync.mp4";
import darklight from "../../../../assets/images/docs/darklight.png";
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import adjusttextsize from "../../../../assets/images/docs/adjusttextsize.png";
import syncedInteractions from "../../../../assets/images/docs/syncedinteractions.png";
import panefooter from "../../../../assets/images/docs/panebottom.png";
import workspace from "../../../../assets/images/docs/ui-workspace.png";
import layouts from "../../../../assets/images/docs/layouts.png";
import settings from "../../../../assets/images/docs/settings.png";
import customworkspace from "../../../../assets/images/docs/customworkspace.png";
import selectdevtools from "../../../../assets/images/docs/select-devtools.png";
import panel from "../../../../assets/images/docs/ui-panel.png";
import * as React from 'react';
export default {
  navsync,
  darklight,
  ShortCutDisplay,
  adjusttextsize,
  syncedInteractions,
  panefooter,
  workspace,
  layouts,
  settings,
  customworkspace,
  selectdevtools,
  panel,
  React
};