import { colord } from 'colord';
import React, { useState, } from 'react';

import './colorchooser.css';
import Colorpicker from './colorpicker';

export default function ColorChooser() {
  const [color, setColor] = useState('#fff');

  const eyeDropper = typeof window !== 'undefined' && window.EyeDropper ? new EyeDropper() : false;

  const pickColor = async e => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const pickedColor = await eyeDropper.open();

      if (pickedColor.sRGBHex) {
        setColor(colord(pickedColor.sRGBHex).rgba);
      }
    } catch (e) {
      console.log("meh", e);
    }


  };

  return eyeDropper ?
    <div className="poly-color-chooser-example">

      <div className="previewwrap">
        <button className="preview" style={{ background: colord(color).toRgbString() }} />
      </div>
      <div className="forms">
        <button type="button" onClick={pickColor}>
          <Colorpicker width={20} height={20} strokeWidth="1.5" color="var(--iconColor)" />
        </button>
        <output>{colord(color).toHex()}</output>
      </div>
    </div> :
    <div className="poly-color-chooser-example">
      <em>The EyeDropper API is not supported. Please use Polypane or another Chromium-based browser.</em>
    </div>

}
