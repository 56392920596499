import debugpanel from "../../../../assets/images/blogs/pp14/debug.png";
import tabsession from "../../../../assets/images/blogs/pp14/tabsession.png";
import tabpane from "../../../../assets/images/blogs/pp14/tabpane.png";
import newui from "../../../../assets/images/blogs/pp14/newui.png";
import fontstyling from "../../../../assets/images/blogs/pp14/fontstyling.png";
import consoleresponse from "../../../../assets/images/blogs/pp14/consoleresponse.png";
import bsky from "../../../../assets/images/blogs/pp14/bsky.jpg";
import cmvretinitis from "../../../../assets/images/docs/debugtools/cmvretinitis.jpg";
import * as React from 'react';
export default {
  debugpanel,
  tabsession,
  tabpane,
  newui,
  fontstyling,
  consoleresponse,
  bsky,
  cmvretinitis,
  React
};