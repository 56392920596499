import { css } from 'astroturf';
import PropTypes from 'prop-types';
import React from 'react';

const styles = css`
  .shortcutdisplay {
    background: rgb(222, 232, 244);
    color: rgb(51, 51, 56);
    border-radius: 0.2em;
    line-height: 12px;
    padding: 4px 6px;
    text-align: center;
    vertical-align: baseline;
    font-family: inherit;
    white-space: nowrap;
    font-feature-settings: "cv08";
    font-weight: 600;
    font-size: 0.9em;
    filter: drop-shadow(rgba(0, 120, 180, 0.15) 0px 3px 0px) drop-shadow(rgba(0, 60, 120, 0.2) 0px 3px 3px);
    margin-top: 5px;
    display: inline-block;
  }
`;

const isThisMac = () => /Mac|iPod|iPhone|iPad/.test(navigator.platform);

class ShortCutDisplay extends React.Component {
  constructor() {
    super();

    this.state = {
      isMac: true,
    };
  }

  componentDidMount() {
    this.setState({
      isMac: isThisMac(),
    });
  }

  render() {
    const { shiftKey = false, cmdKey = false, altKey = false, ctrlKey = false, letter } = this.props;
    const { isMac } = this.state;

    const SHIFT = isMac ? '⇧' : 'Shift';
    const CMD = isMac ? '⌘' : 'Ctrl';
    const ALT = isMac ? '⌥' : 'Alt';

    const string = [shiftKey ? SHIFT : '', cmdKey ? CMD : '', altKey ? ALT : '', ctrlKey ? 'Ctrl' : '', letter]
      .filter((x) => x)
      .join(' ');

    return (
      <kbd className={styles.shortcutdisplay} aria-label={`Shortcut: ${string.trim()}`}>
        {string}
      </kbd>
    );
  }
}

ShortCutDisplay.propTypes = {
  shiftKey: PropTypes.bool,
  cmdKey: PropTypes.bool,
  ctrlKey: PropTypes.bool,
  altKey: PropTypes.bool,
  letter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShortCutDisplay.defaultProps = {
  shiftKey: false,
  cmdKey: false,
  ctrlKey: false,
  altKey: false,
  letter: undefined,
};

export default ShortCutDisplay;
