import { css } from 'astroturf';

const p = css`
  .no1 {
    transform: perspective(75em) rotateX(18deg);
    box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px, rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
    border-radius: 10px;
    border: 1px solid;
    border-color: rgb(213, 220, 226) rgb(213, 220, 226) rgb(184, 194, 204);
  }
  .no2 {
    width: 30vmin;
    height: 30vmin;
    transform: perspective(1500px) rotateY(15deg);
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: transform 1s ease 0s;
    background: linear-gradient(135deg, #f4dabf 50%, #1e5f75 50.1%);
    &:hover {
      transform: perspective(3000px) rotateY(5deg);
    }
  }

  .no3 {
    width: 30vmin;
    height: 15vmin;
    transform: rotate3d(0.5, -0.866, 0, 15deg) rotate(1deg);
    box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, 0.5), 1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
    border-radius: 0.5em;
    will-change: transform;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    background: rgb(29, 31, 32);
    background-size: 16px 16px;
    background-position: center;
    background-image: linear-gradient(to right, rgba(55, 55, 255, 0.4) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(55, 55, 255, 0.4) 1px, transparent 1px);
    border: 1px solid #2b2b2b;

    &:hover {
      transform: rotate3d(0, 0, 0, 0deg) rotate(0deg);
    }
  }

  .no4 {
    height: 20vmin;
    transform: perspective(800px) rotateY(-8deg);
    transition: transform 1s ease 0s;
    border-radius: 4px;
    background: rgb(240, 238, 240);
    box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
      rgba(0, 0, 0, 0.03) 0px 0px 8px 0px, rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;

    &:hover {
      transform: perspective(800px) rotateY(-4deg);
    }
  }

  .no5 {
    width: 17vmin;
    transform: perspective(1000px) rotateX(4deg) rotateY(-16deg) rotateZ(4deg);
    box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    background-size: 16px 16px;
    background-position: center;
    background-image: linear-gradient(to right, rgba(55, 55, 255, 0.2) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(55, 55, 255, 0.2) 1px, transparent 1px);
  }

  .no6 {
    width: 13vmin;
    background-color: #fff;
    will-change: transform;
    transform: rotateX(51deg) rotateY(0deg) rotateZ(43deg);
    transform-style: preserve-3d;
    border-radius: 32px;
    box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01), 28px 28px 28px 0 rgba(34, 33, 81, 0.25);
    transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;

    &:hover {
      transform: translate3d(0px, -16px, 0px) rotateX(51deg) rotateY(0deg) rotateZ(43deg);
      box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
        54px 54px 28px -10px rgba(34, 33, 81, 0.15);
    }
  }

  .no7 {
    height: 18vmin;
    background: #0b0b0f;
    background-size: 16px 16px;
    background-position: center;
    background-image: linear-gradient(to right, rgba(55, 55, 255, 0.4) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(55, 55, 255, 0.4) 1px, transparent 1px);
    transform: perspective(2000px) translate3d(0px, -66px, 198px) rotateX(-55deg) scale3d(0.86, 0.75, 1)
      translateY(50px);
    border-radius: 5px;
    will-change: transform;
    transition: 0.4s ease-in-out transform;

    &:hover {
      transform: scale3d(1, 1, 1);
    }
  }

  .no8 {
    height: 18vmin;
    transform: perspective(750px) translate3d(0px, 0px, -250px) rotateX(27deg) scale(0.9, 0.9);
    background: #fff;
    border-radius: 20px;
    border: 5px solid #e6e6e6;
    box-shadow: 0 70px 40px -20px rgba(0, 0, 0, 0.2);
    transition: 0.4s ease-in-out transform;

    &:hover {
      transform: translate3d(0px, 0px, -250px);
    }
  }

  .no9 {
    width: 34vmin;
    height: 17vmin;
    transform: perspective(600px) rotateX(20deg);
    background: #181818;
    background-size: 16px 16px;
    background-position: -1px -1px;
    background-image: linear-gradient(to right, rgba(55, 55, 255, 0.4) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(55, 55, 255, 0.4) 1px, transparent 1px);
    border: 1px solid #2b2b2b;
    border-radius: 6px;
  }

  .no10 {
    width: 14vmin;
    transform: perspective(900px) rotateX(60deg) scale(0.7);
    background: linear-gradient(to bottom, #eba65b 30%, #d99267 100%);
    box-shadow: 0px 20px 100px #555;
    transition: 0.5s ease all;

    &:hover {
      transform: rotate(0deg) scale(1) translateY(10px);
    }
  }

  .no11 {
    position: relative;
    background: #fbe806;
    width: 16vmin;
    transform: scale(0.75) rotateY(-30deg) rotateX(45deg) translateZ(4.5rem);
    transform-origin: 50% 100%;
    transform-style: preserve-3d;
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
    transition: 0.6s ease transform;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 5px solid #f96b59;
      transform: translateZ(4rem);
      transition: 0.6s ease transform;
    }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f96b59;
      transform: translateZ(-4rem);
      box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
      transition: 0.6s ease transform;
    }

    &:hover {
      transform: scale(1);

      &::before {
        transform: translateZ(0rem);
      }
      &::after {
        transform: translateZ(-1px);
      }
    }
  }

  .no12 {
    position: relative;
    width: 16vmin;
    height: 30vmin;
    border-radius: 1em;
    background: #333232;
    box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.25), 0 0.02125em 0.06125em rgba(0, 0, 0, 0.25);
    perspective: 600px;

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 0 0 1em 1em;
      width: 100%;
      height: 50%;
      transform-origin: center top;
      transform: rotateX(180deg);
      background: #333232 linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.4));
      transition: 0.7s ease-in-out transform;
    }

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 00%;
      border-radius: 1em;
      width: 100%;
      height: 100%;
      transform-origin: center top;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1) 50%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0.1)
      );
    }

    &:hover::before {
      transform: rotateX(0);
    }
  }

  .no13 {
    width: 30vmin;
    height: 30vmin;
    border-radius: 2rem;
    background: linear-gradient(325deg, #3f5efb 0%, #fc466b 100%);

    transform: perspective(800px) rotateY(25deg) scale(0.9) rotateX(10deg);
    filter: blur(2px);
    opacity: 0.5;
    transition: 0.6s ease all;

    &:hover {
      transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1);
      filter: blur(0);
      opacity: 1;
    }
  }
`;

export default p;
