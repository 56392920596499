import panescreenshot from "../../../../assets/images/blogs/screenshots/panescreenshot.png";
import overviewscreenshot from "../../../../assets/images/blogs/screenshots/overview.png";
import elempanel1 from "../../../../assets/images/blogs/screenshots/elempanel1.png";
import elempanel2 from "../../../../assets/images/blogs/screenshots/elempanel2.png";
import saveoptions from "../../../../assets/images/blogs/screenshots/saveoptions.png";
import globalscreenshotsettings from "../../../../assets/images/blogs/screenshots/globalscreenshotsettings.png";
import viewportvid from "../../../../assets/videos/blogs/screenshots/viewport.mp4";
import fullpagevid from "../../../../assets/videos/blogs/screenshots/fullpage.mp4";
import elementvid from "../../../../assets/videos/blogs/screenshots/element-no-padding.mp4";
import elementvid2 from "../../../../assets/videos/blogs/screenshots/element-with-padding.mp4";
import overviewvid from "../../../../assets/videos/blogs/screenshots/overview.mp4";
import allviewportsvid from "../../../../assets/videos/blogs/screenshots/allviewports.mp4";
import allfullpagevid from "../../../../assets/videos/blogs/screenshots/allfullpage.mp4";
import annotatevid from "../../../../assets/videos/blogs/screenshots/annotate.mp4";
import cropvid from "../../../../assets/videos/blogs/screenshots/crop.mp4";
import redactvid from "../../../../assets/videos/blogs/screenshots/redact.mp4";
import * as React from 'react';
export default {
  panescreenshot,
  overviewscreenshot,
  elempanel1,
  elempanel2,
  saveoptions,
  globalscreenshotsettings,
  viewportvid,
  fullpagevid,
  elementvid,
  elementvid2,
  overviewvid,
  allviewportsvid,
  allfullpagevid,
  annotatevid,
  cropvid,
  redactvid,
  React
};