import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import googleserp from "../../../../assets/images/blogs/pp22/googleserp.png";
import socialscreenshot from "../../../../assets/videos/blogs/pp22/socials.mp4";
import offset from "../../../../assets/videos/blogs/pp22/offset.mp4";
import pp22devices from "../../../../assets/images/blogs/pp22/pp22devices.png";
import collapsedmeta from "../../../../assets/images/blogs/pp22/collapsedmeta.png";
import customproptooltip from "../../../../assets/images/docs/elements/customprop-tooltip.png";
import pseudostates from "../../../../assets/images/docs/elements/pseudostates.png";
import placeholdifiertranslate from "../../../../assets/images/docs/debugtools/placeholdifier-translate.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  googleserp,
  socialscreenshot,
  offset,
  pp22devices,
  collapsedmeta,
  customproptooltip,
  pseudostates,
  placeholdifiertranslate,
  React
};