// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-accessibility-index-jsx": () => import("./../../../src/pages/accessibility/index.jsx" /* webpackChunkName: "component---src-pages-accessibility-index-jsx" */),
  "component---src-pages-accessibility-statement-jsx": () => import("./../../../src/pages/accessibility-statement.jsx" /* webpackChunkName: "component---src-pages-accessibility-statement-jsx" */),
  "component---src-pages-accolades-jsx": () => import("./../../../src/pages/accolades.jsx" /* webpackChunkName: "component---src-pages-accolades-jsx" */),
  "component---src-pages-alternative-index-jsx": () => import("./../../../src/pages/alternative/index.jsx" /* webpackChunkName: "component---src-pages-alternative-index-jsx" */),
  "component---src-pages-black-friday-dear-manager-jsx": () => import("./../../../src/pages/black-friday/dear-manager.jsx" /* webpackChunkName: "component---src-pages-black-friday-dear-manager-jsx" */),
  "component---src-pages-black-friday-index-jsx": () => import("./../../../src/pages/black-friday/index.jsx" /* webpackChunkName: "component---src-pages-black-friday-index-jsx" */),
  "component---src-pages-calculator-jsx": () => import("./../../../src/pages/calculator.jsx" /* webpackChunkName: "component---src-pages-calculator-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-color-contrast-jsx": () => import("./../../../src/pages/color-contrast.jsx" /* webpackChunkName: "component---src-pages-color-contrast-jsx" */),
  "component---src-pages-create-workspace-jsx": () => import("./../../../src/pages/create-workspace.jsx" /* webpackChunkName: "component---src-pages-create-workspace-jsx" */),
  "component---src-pages-css-3-d-transform-examples-index-jsx": () => import("./../../../src/pages/css-3d-transform-examples/index.jsx" /* webpackChunkName: "component---src-pages-css-3-d-transform-examples-index-jsx" */),
  "component---src-pages-css-specificity-calculator-jsx": () => import("./../../../src/pages/css-specificity-calculator.jsx" /* webpackChunkName: "component---src-pages-css-specificity-calculator-jsx" */),
  "component---src-pages-dear-manager-jsx": () => import("./../../../src/pages/dear-manager.jsx" /* webpackChunkName: "component---src-pages-dear-manager-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-docs-changelog-jsx": () => import("./../../../src/pages/docs/changelog.jsx" /* webpackChunkName: "component---src-pages-docs-changelog-jsx" */),
  "component---src-pages-docs-faq-jsx": () => import("./../../../src/pages/docs/faq.jsx" /* webpackChunkName: "component---src-pages-docs-faq-jsx" */),
  "component---src-pages-docs-index-jsx": () => import("./../../../src/pages/docs/index.jsx" /* webpackChunkName: "component---src-pages-docs-index-jsx" */),
  "component---src-pages-docs-ui-overview-jsx": () => import("./../../../src/pages/docs/ui-overview.jsx" /* webpackChunkName: "component---src-pages-docs-ui-overview-jsx" */),
  "component---src-pages-download-jsx": () => import("./../../../src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-experimental-web-platform-features-jsx": () => import("./../../../src/pages/experimental-web-platform-features.jsx" /* webpackChunkName: "component---src-pages-experimental-web-platform-features-jsx" */),
  "component---src-pages-exported-panes-jsx": () => import("./../../../src/pages/exported-panes.jsx" /* webpackChunkName: "component---src-pages-exported-panes-jsx" */),
  "component---src-pages-github-students-jsx": () => import("./../../../src/pages/github-students.jsx" /* webpackChunkName: "component---src-pages-github-students-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-index-jsx": () => import("./../../../src/pages/integrations/index.jsx" /* webpackChunkName: "component---src-pages-integrations-index-jsx" */),
  "component---src-pages-integrations-jetbrains-webstorm-jsx": () => import("./../../../src/pages/integrations/jetbrains-webstorm.jsx" /* webpackChunkName: "component---src-pages-integrations-jetbrains-webstorm-jsx" */),
  "component---src-pages-integrations-markerio-jsx": () => import("./../../../src/pages/integrations/markerio.jsx" /* webpackChunkName: "component---src-pages-integrations-markerio-jsx" */),
  "component---src-pages-integrations-toybox-systems-jsx": () => import("./../../../src/pages/integrations/toybox-systems.jsx" /* webpackChunkName: "component---src-pages-integrations-toybox-systems-jsx" */),
  "component---src-pages-integrations-tumult-hype-jsx": () => import("./../../../src/pages/integrations/tumult-hype.jsx" /* webpackChunkName: "component---src-pages-integrations-tumult-hype-jsx" */),
  "component---src-pages-integrations-tumult-whisk-jsx": () => import("./../../../src/pages/integrations/tumult-whisk.jsx" /* webpackChunkName: "component---src-pages-integrations-tumult-whisk-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-og-screenshot-jsx": () => import("./../../../src/pages/og-screenshot.jsx" /* webpackChunkName: "component---src-pages-og-screenshot-jsx" */),
  "component---src-pages-opener-jsx": () => import("./../../../src/pages/opener.jsx" /* webpackChunkName: "component---src-pages-opener-jsx" */),
  "component---src-pages-portal-index-jsx": () => import("./../../../src/pages/portal/index.jsx" /* webpackChunkName: "component---src-pages-portal-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-tour-jsx": () => import("./../../../src/pages/product-tour.jsx" /* webpackChunkName: "component---src-pages-product-tour-jsx" */),
  "component---src-pages-quality-assurance-index-jsx": () => import("./../../../src/pages/quality-assurance/index.jsx" /* webpackChunkName: "component---src-pages-quality-assurance-index-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-responsive-design-glossary-jsx": () => import("./../../../src/pages/responsive-design-glossary.jsx" /* webpackChunkName: "component---src-pages-responsive-design-glossary-jsx" */),
  "component---src-pages-responsive-website-test-jsx": () => import("./../../../src/pages/responsive-website-test.jsx" /* webpackChunkName: "component---src-pages-responsive-website-test-jsx" */),
  "component---src-pages-screenshot-comparison-jsx": () => import("./../../../src/pages/screenshot-comparison.jsx" /* webpackChunkName: "component---src-pages-screenshot-comparison-jsx" */),
  "component---src-pages-site-quality-index-jsx": () => import("./../../../src/pages/site-quality/index.jsx" /* webpackChunkName: "component---src-pages-site-quality-index-jsx" */),
  "component---src-pages-social-media-previews-index-jsx": () => import("./../../../src/pages/social-media-previews/index.jsx" /* webpackChunkName: "component---src-pages-social-media-previews-index-jsx" */),
  "component---src-pages-special-offers-jsx": () => import("./../../../src/pages/special-offers.jsx" /* webpackChunkName: "component---src-pages-special-offers-jsx" */),
  "component---src-pages-support-index-jsx": () => import("./../../../src/pages/support/index.jsx" /* webpackChunkName: "component---src-pages-support-index-jsx" */),
  "component---src-pages-syntax-jsx": () => import("./../../../src/pages/syntax.jsx" /* webpackChunkName: "component---src-pages-syntax-jsx" */),
  "component---src-pages-tailwindcss-jsx": () => import("./../../../src/pages/tailwindcss.jsx" /* webpackChunkName: "component---src-pages-tailwindcss-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-visualize-browser-sizes-jsx": () => import("./../../../src/pages/visualize-browser-sizes.jsx" /* webpackChunkName: "component---src-pages-visualize-browser-sizes-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-doc-jsx": () => import("./../../../src/templates/doc.jsx" /* webpackChunkName: "component---src-templates-doc-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-term-jsx": () => import("./../../../src/templates/term.jsx" /* webpackChunkName: "component---src-templates-term-jsx" */)
}

