import { css } from 'astroturf';
import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';

/* TODO
  generalize main UI (not buttonbar)

*/

const styles = css`
  .offsetexample {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    border-radius: 8px;
    overflow: hidden;
    background: #f7faff;

    @media (max-width: 40rem) {
      grid-template-columns: 1fr;
      grid-template-rows:  repeat(2, minmax(200px, 1fr));
    }
  }

  .buttonbar {
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    overflow: hidden;
    text-align: center;
    background: #1e1e3f;
    color: #fff;
    border-radius: 8px;
    padding: 0.5rem;

    & p {
      padding: 0.5rem;
    }

    & button {
      padding: 0.5rem;
      text-align: center;
      background: #1e1e3f;
      color: #fff;
      border-radius: 8px;
      border: 0;
      cursor: pointer;
      background: rgb(16, 125, 181);
      font-size: inherit;
      border: 2px solid #1e1e3f;
      font-weight: 600;

      &:hover,
      &:focus-visible {
        background: #f7faff;
        color: #1e1e3f;
      }
    }
  }

  .code {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    background-color: #f7faff;

    & pre {
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      min-width:1000px;
      background: transparent !important;
    }

    & > div > div {
      margin: 0 !important;
      width: 100%;
    }

    & .block {
      position: relative;
      overflow: hidden;
      max-height: 20rem;
      display: block;
      border-radius: 8px;
      padding: 1rem;
      background: #1e1e3f;
      color: #fff;
      border-radius: 8px;

      & > div {
        overflow:visible;
      }

      & > div,
      & > textarea {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        position: absolute;
        inset: 1rem;
        white-space: pre-wrap;
      }
    }

    & textarea {
      border: 0;
      z-index: 2;

      background: none;
      color: transparent;

      resize: none;

      direction: ltr;
      text-align: left;
      overflow: auto;
      white-space: nowrap;
      word-spacing: normal;
      word-break: normal;
      tab-size: 4;
      hyphens: none;
      font-family: 'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.5px;
      outline: none;
      caret-color: #fff;
    }

    & textarea::selection {
      background: #fff;
      color: #000;
    }
  }

  .block::before,
  .result::before {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0.125rem 0.5rem 0 0.25rem;
    background: #1e1e3f;
    color: #fff;
    font-size: 0.6rem;
    line-height: 1.25;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid #f7faff;
    right: 0;
    border-radius: 0 0 0 0.25rem;
    border-width: 0 0 2px 2px;
    pointer-events: none;
  }
  .block:global(:has(pre.language-html))::before {
    content: 'html';
  }
  .block:global(:has(pre.language-css))::before {
    content: 'css';
    top: 2px;
  }

  .result {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: #1e1e3f;

    & iframe {
      background-color: #1e1e3f;
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }

    &::before {
      z-index: 1;
      content: 'result';
    }
  }
`;

const htmlInit = `<div class="parent">
  <blockquote>
    <span>&ldquo;</span>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora
    tenetur molestias consequatur, quidem nam ipsum minima aliquid
    recusandae delectus enim.
  </blockquote>
</div>`;

const cssCodeInit = `blockquote { /*position: relative;*/ }

blockquote span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10em;
  font-family: serif;
  font-weight: bold;
  line-height:0.5;
  color: palegreen;
}`;

const fixedCssCode = `blockquote { position: relative; }

blockquote span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10em;
  font-family: serif;
  font-weight: bold;
  line-height:0.5;
  color: palegreen;
}`;

const Editor = ({
  html,
  cssCode,
  setHtml,
  setCSS,
  htmlHeight,
  cssHeight,
  htmlOffset,
  setHtmlOffset,
  cssOffset,
  setCssOffset,
  combination,
}) => {
  const iframe = useRef(null);

  useEffect(() => {
    Prism.highlightAll();
    iframe.current.srcdoc = combination;
  }, [cssCode, html]);

  return (
    <div className={styles.offsetexample}>
      <div className={styles.code}>
        <div className={styles.block} style={{ height: htmlHeight }}>
          <div className="gatsby-highlight" style={{ translate: `-${htmlOffset.x}px -${htmlOffset.y}px` }}>
            <pre className="language-html">
              <code>{html}</code>
            </pre>
          </div>
          <textarea
            spellCheck={false}
            value={html}
            onScroll={(e) => setHtmlOffset({ x: e.target.scrollLeft, y: e.target.scrollTop })}
            onChange={(e) => setHtml(e.target.value)}
          />
        </div>
        <div className={styles.block} style={{ height: cssHeight }}>
          <div className="gatsby-highlight" style={{ translate: `-${cssOffset.x}px -${cssOffset.y}px` }}>
            <pre className="language-css">
              <code>{cssCode}</code>
            </pre>
          </div>
          <textarea
            spellCheck={false}
            value={cssCode}
            onScroll={(e) => setCssOffset({ x: e.target.scrollLeft, y: e.target.scrollTop })}
            onChange={(e) => setCSS(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.result}>
        <iframe ref={iframe} title="result" sandbox="true" />
      </div>
    </div>
  );
};

const OffsetExample = () => {
  const [html, setHtml] = React.useState(htmlInit);
  const [cssCode, setCSS] = React.useState(cssCodeInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          min-height: 100vh;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        .parent {
          background: #3e3e5f;
          padding: 1rem;
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={224}
        cssHeight={288}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
      <div className={styles.buttonbar}>
        <button type="button" onClick={(e) => setCSS(cssCode.indexOf('/*') > -1 ? fixedCssCode : cssCodeInit)}>
          {cssCode.indexOf('/*') > -1 ? 'Add position to blockquote' : 'Remove position from blockquote'}
        </button>

        <p>
          Offset parent: <code>&lt;{cssCode.indexOf('/*') > -1 ? 'body' : 'blockquote'}&gt;</code>
        </p>
      </div>
    </div>
  );
};

const stackCssCode = `blockquote span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10em;
  font-family: serif;
  font-weight: bold;
  line-height:0.5;
  color: palegreen;
}`;

const stackCssCodeNegZindex = `blockquote span {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10em;
  font-family: serif;
  font-weight: bold;
  line-height:0.5;
  color: palegreen;
}`;

const stackCssCodeIsolate = `.parent {
  isolation: isolate;
}

blockquote span {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10em;
  font-family: serif;
  font-weight: bold;
  line-height:0.5;
  color: palegreen;
}`;

const StackingExample = () => {
  const [html, setHtml] = React.useState(htmlInit);
  const [cssCode, setCSS] = React.useState(stackCssCode);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          min-height: 100vh;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        .parent {
          background: #3e3e5f;
          padding: 1rem;
        }

        blockquote { position: relative; }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={224}
        cssHeight={288}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
      <div className={styles.buttonbar}>
        <div>
          Step 1:{' '}
          <button
            type="button"
            onClick={(e) => setCSS(cssCode.indexOf('z-index') > -1 ? stackCssCode : stackCssCodeNegZindex)}
          >
            {cssCode.indexOf('z-index') > -1 ? 'Remove z-index' : 'Add z-index'}
          </button>{' '}
          Step 2:{' '}
          <button
            type="button"
            onClick={(e) => setCSS(cssCode.startsWith('.parent') ? stackCssCodeNegZindex : stackCssCodeIsolate)}
          >
            {cssCode.startsWith('.parent') ? 'Remove isolate' : 'Add isolate'}
          </button>
        </div>
        <p>
          Stacking Context: <code>&lt;{cssCode.indexOf('isolation') > -1 ? 'div class="parent"' : 'html'}&gt;</code>
        </p>
      </div>
    </div>
  );
};

export { OffsetExample, StackingExample };
