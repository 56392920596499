import cookie4 from "../../../../assets/images/docs/cookie4.png";
import storage1 from "../../../../assets/images/docs/storage1.png";
import idrefs from "../../../../assets/images/blogs/pp18/idrefs.png";
import targetcheck from "../../../../assets/images/blogs/pp18/targetcheck.png";
import targetcheck2 from "../../../../assets/images/blogs/pp18/targetcheck2.png";
import webcomponents from "../../../../assets/images/blogs/pp18/webcomponents.png";
import focusbutton from "../../../../assets/images/blogs/pp18/focusbutton.png";
import focusalignment from "../../../../assets/images/blogs/pp18/focusalignment.png";
import context from "../../../../assets/images/blogs/pp18/context.png";
import clearbtn from "../../../../assets/images/blogs/pp18/clearbtn.png";
import marginauto from "../../../../assets/images/blogs/pp18/margin-auto.png";
import suggestions from "../../../../assets/images/blogs/pp18/suggestions.mp4";
import metatagsuggestions from "../../../../assets/images/blogs/pp18/metatagsuggestions.png";
import validator from "../../../../assets/images/blogs/pp18/validator.png";
import perplexity from "../../../../assets/images/blogs/pp18/perplexity.png";
import layoutdebuggerindicator from "../../../../assets/images/blogs/pp18/debugger.png";
import portal from "../../../../assets/videos/portal.mp4";
import * as React from 'react';
export default {
  cookie4,
  storage1,
  idrefs,
  targetcheck,
  targetcheck2,
  webcomponents,
  focusbutton,
  focusalignment,
  context,
  clearbtn,
  marginauto,
  suggestions,
  metatagsuggestions,
  validator,
  perplexity,
  layoutdebuggerindicator,
  portal,
  React
};