import apca from "../../../../assets/images/blogs/colorcontrastapp/apca.png";
import lightdark from "../../../../assets/images/blogs/colorcontrastapp/lightdark.png";
import simulators from "../../../../assets/images/blogs/colorcontrastapp/simulators.mp4";
import previews from "../../../../assets/images/blogs/colorcontrastapp/previews.mp4";
import * as React from 'react';
export default {
  apca,
  lightdark,
  simulators,
  previews,
  React
};