import { useEffect } from 'react';
// react component that loads <script src="https://cdn.changelog.com/embed.js"></script> into the DOM

const LoadJSPartyEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.changelog.com/embed.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default LoadJSPartyEmbed;
