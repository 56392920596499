import React from 'react';

const Colorpicker = ({
  className,
  width = 30,
  height = 30,
  strokeWidth = '1',
  strokeLinejoin = 'round',
  strokeLinecap = 'round',
  color = 'currentcolor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-colorpicker"
    width={30}
    height={30}
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    stroke={color}
    fill="none"
    strokeLinecap={strokeLinecap}
    strokeLinejoin={strokeLinejoin}
  >
    <path d="M11 7l6 6" />
    <path d="M4 16l11.7 -11.7a1 1 0 0 1 1.4 0l2.6 2.6a1 1 0 0 1 0 1.4l-11.7 11.7h-4v-4z" />
  </svg>
);

export default Colorpicker;
