import a11ytree from "../../../../assets/images/blogs/pp201/a11ytree.png";
import defaultfontsize from "../../../../assets/images/blogs/pp201/defaultfontsize.png";
import threads from "../../../../assets/images/blogs/pp201/threads.png";
import charcount from "../../../../assets/images/blogs/pp201/charcount.png";
import sharescrollpos from "../../../../assets/images/blogs/pp201/sharescrollpos.png";
import colornotationpeek from "../../../../assets/images/blogs/pp201/colornotationpeek.png";
import horizoverflowlog from "../../../../assets/images/blogs/pp201/horizoverflowlog.png";
import customdevices from "../../../../assets/images/blogs/pp201/customdevices.png";
import paste from "../../../../assets/images/blogs/pp201/paste.mp4";
import duplicateids from "../../../../assets/images/blogs/pp201/duplicateids.png";
import copysvg from "../../../../assets/images/blogs/pp201/copysvg.mp4";
import networkEmulation from "../../../../assets/images/docs/network-emulation.png";
import * as React from 'react';
export default {
  a11ytree,
  defaultfontsize,
  threads,
  charcount,
  sharescrollpos,
  colornotationpeek,
  horizoverflowlog,
  customdevices,
  paste,
  duplicateids,
  copysvg,
  networkEmulation,
  React
};