import { OffsetExample, StackingExample } from "../../../../src/components/offset/offsetExample";
import offsetpanel1 from "../../../../assets/images/blogs/offset/offsetpanel1.png";
import offsetpanel2 from "../../../../assets/images/blogs/offset/offsetpanel2.png";
import * as React from 'react';
export default {
  OffsetExample,
  StackingExample,
  offsetpanel1,
  offsetpanel2,
  React
};