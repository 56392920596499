import dash from "../../../../assets/images/blogs/cloud/dash.png";
import scan from "../../../../assets/images/blogs/cloud/scan.png";
import page from "../../../../assets/images/blogs/cloud/page.png";
import issues from "../../../../assets/images/blogs/cloud/issues.png";
import wcag from "../../../../assets/images/blogs/cloud/wcag.png";
import * as React from 'react';
export default {
  dash,
  scan,
  page,
  issues,
  wcag,
  React
};