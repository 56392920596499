import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import browse1 from "../../../../assets/images/docs/browse/browse1.png";
import browse2 from "../../../../assets/images/docs/browse/browse2.png";
import browsesearch1 from "../../../../assets/images/docs/browse/search1.png";
import browsesearch2 from "../../../../assets/images/docs/browse/search2.png";
import homepage from "../../../../assets/images/docs/browse/homepage.png";
import browseext1 from "../../../../assets/images/docs/browse/ext1.png";
import browseext2 from "../../../../assets/images/docs/browse/ext2.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  browse1,
  browse2,
  browsesearch1,
  browsesearch2,
  homepage,
  browseext1,
  browseext2,
  React
};