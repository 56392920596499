import { Camera } from 'react-bytesize-icons';
import screenshotoverview from "../../../../assets/images/docs/screenshot-overview.png";
import screenshotsingle from "../../../../assets/images/docs/screenshot-single.png";
import screenshotoptions from "../../../../assets/images/docs/screenshot-options.png";
import padding from "../../../../assets/images/docs/screenshots/padding.png";
import crop from "../../../../assets/images/blogs/pp12/crop.mp4";
import delay from "../../../../assets/images/blogs/pp12/delay.mp4";
import * as React from 'react';
export default {
  Camera,
  screenshotoverview,
  screenshotsingle,
  screenshotoptions,
  padding,
  crop,
  delay,
  React
};