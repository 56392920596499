import { css } from 'astroturf';
import React, { useLayoutEffect, useState } from 'react';
import { Download, Upload } from 'react-bytesize-icons';

const styles = css`
  .workspaceWrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
  }
  .workspaceWrapper .panelist {
    position: relative;
    width: auto;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    min-height: 225px;
    gap: 8px;

    & li {
      margin: 0;
      text-align: center;
      line-height: 2;
    }

    & div {
      margin-bottom: 4px;
      background: rgba(0, 70, 150, 0.1);
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 1px 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.02) 0px 0px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      line-height: 1.2;
      min-height: 160px;
      max-width: 60vw;

      & i {
        font-size: 0.8em;
        display: block;
        opacity: 0.8;
        font-style: normal;
      }
    }
  }

  .buttonContainer {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;

    & .open {
      text-align: center;
      font-size: 1.2rem;
      color: #fff;
      background: #0a1530;
      line-height: 2.5rem;
      white-space: nowrap;
      transition: 0.25s ease-out all;
      flex: 1 1 50%;
      margin: 0;

      &:hocus {
        background: #67b26f;
        &:after {
          display: none;
        }
      }

      & svg {
        vertical-align: middle;
      }
    }

    & .download {
      white-space: nowrap;
      text-align: center;
      color: #fff;
      line-height: 2.5rem;
      transition: 0.25s ease-out all;
      flex: 1 1 50%;
      color: #fff;
      background: #107db5;
      margin: 0;

      &:hocus {
        background: #67b26f;
        &:after {
          display: none;
        }
      }
    }

    & i {
      white-space: nowrap;
      text-align: center;
      line-height: 2.5rem;
      transition: 0.25s ease-out all;
      flex: 1 1 50%;
      background: rgba(0, 70, 150, 0.1);
    }
  }
`;

const WorkspacePreview = ({ sizes, name, download }) => {
  const [openVisible, setOpenVisible] = useState(true);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (window.__polypane) {
        setOpenVisible(false);
      }
    }, 200);
  }, []);

  const open = `polypane://polypane.app/exported-panes/?panesizes=${encodeURIComponent(
    JSON.stringify(
      sizes.map((item) => ({
        width: item.width,
        title: item.name,
      }))
    )
  )}`;

  return (
    <div className={styles.workspaceWrapper}>
      <div className={styles.buttonContainer}>
        {openVisible && (
          <a className={styles.open} href={open}>
            Open in Polypane <Upload width={20} height={20} />
          </a>
        )}
        {download ? (
          <a className={styles.download} href={download} download>
            Download Workspace file <Download width={16} height={16} />
          </a>
        ) : (
          <i>Built into Polypane</i>
        )}
      </div>
      <ul className={[styles.panelist, 'panelist'].join(' ')}>
        {sizes.map((item) => (
          <li key={item.width}>
            <div
              style={{
                width: `${item.width / 8}px`,
                height: `100%`,
              }}
            >
              {item.name}
              <i>{!item.hideWidth ? <>{item.width}px</> : <>&nbsp;</>}</i>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WorkspacePreview;
